import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + "api/"

const getExams = () => {
    return axios.get(API_URL + "exams", { headers: authHeader() });
};

const getTurnsByExam = (data) => {
    return axios.get(API_URL + "exam/turns/" + data.examId, { headers: authHeader() });
};

const getAllBookedTurnsByExam = (data) => {
    return axios.get(API_URL + "exam/all-booked-turns/" + data.examId, { headers: authHeader() });
};

const getBookedTurnsByExam = (data) => {
    return axios.get(API_URL + "exam/booked-turns/" + data.examId, { headers: authHeader() });
};

const getExamResults = (examId) => {
    return axios.get(API_URL + "exam/results/" + examId, { headers: authHeader() });
}

const putReExam = (data) => {
    return axios.put(API_URL + "exam/re-exam", data, { headers: authHeader() });
}

const putCancelReExam = (data) => {
    return axios.put(API_URL + "exam/cancel-re-exam", data, { headers: authHeader() });
}

const examService = {
    getExams,
    getTurnsByExam,
    getBookedTurnsByExam,
    getAllBookedTurnsByExam,
    getExamResults,
    putReExam,
    putCancelReExam,
};
  
export default examService