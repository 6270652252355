import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { 
    // Paper,
    Box,
    Stack,
    // Item,
    Typography,
    Link,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    Button, 
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    FormHelperText,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Alert
} from '@mui/material';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material'

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { _register } from "../../slices/auth";
import { clearMessage } from "../../slices/message";

const Register = () => {
  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector((state) => state.message);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptTC, setAcceptTC] = useState(false);
  const [error, setError] = useState(null);
  const [openTC, setOpenTC] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const dispatch = useDispatch();

  const handleAcceptTC = () => {
    setAcceptTC(true);
  };

  const handleCloseTC = () => {
    setOpenTC(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (openTC) {
      const { current: descriptionElement } = descriptionElementRef;
      // console.log(descriptionElement)
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openTC]);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return 'Ngày sinh không được đặt sau ngày hiện tại';
      }
      case 'invalidDate': {
        return 'Thông tin không hợp lệ';
      }
      case 'required': {
        return 'Thông tin không được bỏ trống';
      }
      default: {
        return '';
      }
    }
  }, [error]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  const initialValues = {
    fullname: "",
    cccd: "",
    gender: -1,
    birthday: null,
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .test(
        "len",
        "Họ và tên quá ngắn",
        (val) =>
          val &&
          val.toString().length >= 3
      )
      .required("Thông tin này không được bỏ trống!"),
    cccd: Yup.string()
      .test(
        "len",
        "Số CCCD phải đủ 12 số",
        (val) =>
          val &&
          val.toString().length === 12
      )
      .required("Thông tin này không được bỏ trống!"),
    gender: Yup.number()
    .test(
      "gender",
      "Chưa chọn giới tính",
      (val) =>
        (val===1 || val===0)
    )
    .required("Thông tin này không được bỏ trống!"),
    birthday: Yup.date()
      .required("Thông tin này không được bỏ trống!"),
    phone: Yup.string()
      .test(
        "len",
        "Số điện thoại phải đủ 10 số",
        (val) =>
          val &&
          val.toString().length == 10
      )
      .required("Thông tin này không được bỏ trống!"),
    email: Yup.string()
      .email("Địa chỉ email không hợp lệ"),
    password: Yup.string()
      .test(
        "len",
        "Mật khẩu không được ít hơn 6 kí tự",
        (val) =>
          !val ||
          (val.toString().length >= 6 &&
          val.toString().length <= 40)
      ),
    confirmPassword: Yup.string()
        .test(
          "len",
          "Mật khẩu không được ít hơn 6 kí tự",
          (val) =>
            !val ||
            (val.toString().length >= 6 &&
            val.toString().length <= 40)
        )
        .oneOf([Yup.ref('password'), null], 'Nhập lại mật khẩu chưa giống'),
    acceptTerms: Yup.boolean()
        .required('Bạn chưa chấp nhận điều khoản dịch vụ')
        .oneOf([true], 'Bạn chưa chấp nhận điều khoản dịch vụ')
  });

  const handleRegister = (formValue) => {
    // alert(JSON.stringify(formValue, null, 2));
    const { fullname, cccd, gender, phone, email, password, acceptTerms } = formValue;
    const birthday = formValue.birthday.format('DD/MM/YYYY');
    // alert(JSON.stringify(birthday, null, 2));

    setSuccessful(false);

    dispatch(_register({ fullname, cccd, birthday, gender, phone, email, password, acceptTerms }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        // navigate("/profile");
        // window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="col-md-12 signup-form">
      <div className="card card-container">
        <img
          src= "/logo_hcmue.png"
          alt="profile-img"
          className="profile-img-card"
        />
          {!successful && (
            <div>
              <Box
              sx={{
              '& .MuiTextField-root': { py: 1 },
              mb: 2
              }}
              noValidate
              autoComplete="off"
              >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleRegister}
              >
              {props => (
              <Form>
              <TextField
              fullWidth
              id="fullname"
              name="fullname"
              label="Họ và tên"
              variant="standard"
              value={props.values.fullname}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.fullname && Boolean(props.errors.fullname)}
              helperText={props.touched.fullname && props.errors.fullname}
              autoComplete="off"
              required
              />
              <TextField
              fullWidth
              id="cccd"
              name="cccd"
              label="Số Căn cước công dân"
              variant="standard"
              value={props.values.cccd}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.cccd && Boolean(props.errors.cccd)}
              helperText={props.touched.cccd && props.errors.cccd}
              autoComplete="off"
              required
              />
              <FormControl 
                fullWidth
              >
                <FormLabel 
                  id="demo-row-radio-buttons-group-label" 
                  size="small"
                  error={props.touched.gender && Boolean(props.errors.gender)}
                  helperText={props.touched.gender && props.errors.gender}
                >
                  Giới tính
                  {
                    props.touched.gender && (
                      <Typography variant="caption" display="block" gutterBottom>
                        {props.errors.gender}
                      </Typography>
                    )
                  }
                </FormLabel>
                <RadioGroup
                  row
                  name="gender"
                  label="One of these please"
                  value={props.values.gender}
                  onChange={props.handleChange}
                  sx={{ mb:1 }}
                >
                  <FormControlLabel value="0" control={<Radio size="small" />} label="Nam" />
                  <FormControlLabel value="1" control={<Radio size="small" />} label="Nữ" />
                </RadioGroup>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker 
                  format="DD/MM/YYYY"
                  fullWidth
                  id="birthday"
                  name="birthday"
                  label="Ngày sinh"
                  variant="standard"
                  value={props.values.birthday}
                  onChange={(value) => props.setFieldValue("birthday", value, true)}
                  onError={(newError) => setError(newError)}
                  maxDate={dayjs()}
                  slotProps={{
                    textField: {
                        error: props.touched.birthday && Boolean(props.errors.birthday),
                        helperText: (props.touched.birthday && props.errors.birthday) || errorMessage
                    }
                  }}
                  required
              />
              </LocalizationProvider>
              <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Số điện thoại liên lạc"
              variant="standard"
              value={props.values.phone}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.phone && Boolean(props.errors.phone)}
              helperText={props.touched.phone && props.errors.phone}
              autoComplete="off"
              required
              />
              <TextField
              fullWidth
              id="email"
              name="email"
              label="Địa chỉ Email"
              variant="standard"
              value={props.values.email}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.email && Boolean(props.errors.email)}
              helperText={props.touched.email && props.errors.email}
              autoComplete="off"
              />
              {/* <TextField
              fullWidth
              id="password"
              name="password"
              label="Mật khẩu"
              type={showPassword ? 'text' : 'password'}
              variant="standard"
              value={props.values.password}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.password && Boolean(props.errors.password)}
              helperText={props.touched.password && props.errors.password}
              autoComplete="off"
              InputProps={{
                  endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  </InputAdornment>
                  ),
              }}
              />
              <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Xác nhận mật khẩu"
              type={showPassword ? 'text' : 'password'}
              variant="standard"
              value={props.values.confirmPassword}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.confirmPassword && Boolean(props.errors.confirmPassword)}
              helperText={props.touched.confirmPassword && props.errors.confirmPassword}
              autoComplete="off"
              InputProps={{
                  endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  </InputAdornment>
                  ),
              }}
              /> */}
              <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptTerms"
                        checked={props.values.acceptTerms}
                        // onChange={(e) => {
                        //   props.setFieldValue("acceptTerms", e.target.checked);
                        // }}
                        onClick={ (e) => {
                          setOpenTC(true);
                        }}
                        />
                    }
                    label={
                      <Typography>
                        Tôi đồng ý chấp nhận điều khoản dịch vụ.
                      </Typography>
                    }
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {props.touched.acceptTerms && props.errors.acceptTerms
                      ? props.touched.acceptTerms && props.errors.acceptTerms
                      : " "}
                  </FormHelperText>
              </FormControl>
              <Button color="success" variant="contained" type="submit" size="large" fullWidth sx={{ mt:1 }}>Đăng ký</Button>
              <Dialog
                open={openTC}
                onClose={handleCloseTC}
                scroll={ scroll }
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  <Typography
                    variant="h6"
                    sx={{ 
                      fontWeight: 'bold',
                      my: 1
                      }}
                  >
                    Điều khoản sử dụng dịch vụ
                  </Typography>
                  <Alert severity="info">
                    Thí sinh vui lòng đọc hết các điều khoản và nhấn vào nút xác nhận ở cuối cùng
                  </Alert>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    sx={{ 
                      typography: 'body1',
                      textAlign: 'justify', 
                      fontWeight: 'regular',
                      lineHeight: 1.5,
                      m: 1 
                    }}
                  >
                    <p>Chào mừng thí sinh đến với trang thông tin điện tử Kỳ thi đánh giá năng lực chuyên biệt của Trường Đại học Sư phạm Thành phố Hồ Chí Minh (sau đây gọi tắt là Trường).</p>
                    <p>Cùng với việc truy cập trang dgnl.hcmue.edu.vn và sử dụng các dịch vụ, thí sinh đồng ý bị ràng buộc với Điều khoản Sử dụng này, Chính sách Quảng cáo và Chính sách Bảo mật của chúng tôi.</p>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    1.	Đăng ký tài khoản
                    </Typography>
                    <ul>
                      <li>Để sử dụng dịch vụ trên trang thông tin điện tử dgnl.hcmue.edu.vn, thí sinh đăng ký tài khoản với các thông tin cơ bản theo yêu cầu;</li>
                      <li>Sau khi đăng ký thành công, thí sinh đăng nhập với thông tin tài khoản với tài khoản là số Căn cước công dân (CCCD) và mật khẩu mặc định là ngày sinh theo định dạng DDMMYYYY (ví dụ: 25122023).</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    2.	Quyền được bảo mật thông tin
                    </Typography>
                    <ul>
                      <li>Khi sử dụng dịch vụ trên trang dgnl.hcmue.edu.vn, thí sinh được đảm bảo rằng những thông tin bạn cung cấp sẽ chỉ được dùng vào mục đích thi và nâng cao hiệu quả phục vụ của Trường. Những thông tin này sẽ không được chuyển giao cho bên thứ ba nào khác vì mục đích thương mại. Những thông tin này hoàn toàn được bảo mật, chỉ trong trường hợp pháp luật yêu cầu, Trường buộc phải cung cấp thông tin cho cơ quan chức năng theo quy định pháp luật;</li>
                      <li>Thông tin của thí sinh cùng với kết quả thi được lưu trữ 2 năm trên website và được lưu trữ theo nghiệp vụ tại Trường.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    3.	Kết nối vào/ra từ website
                    </Typography>
                    <ul>
                      <li>Một số nội dung trên trang dgnl.hcmue.edu.vn chỉ liên kết tới một số trang thông tin điện tử khác của Trường có phần địa chỉ gốc hcmue.edu.vn. Trường không kết nối hoặc điều hướng đến các trang thông tin điện tử của đơn vị hoặc đối tác khác;</li>
                      <li>Khi thí sinh phát hiện nội dung có các đường dẫn tới các trang khác ngoài tên miền hcmue.edu.vn; vui lòng không truy cập vào các nội dung này.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    4.	Trách nhiệm của thí sinh
                    </Typography>
                    <ul>
                      <li>Thí sinh cần đọc kỹ tất cả các thông tin Điều khoản dịch vụ khi đăng ký dự thi;</li>
                      <li>Thí sinh tuyệt đối không được xâm nhập bất hợp pháp vào hệ thống hoặc làm thay đổi cấu trúc dữ liệu của trang dgnl.hcmue.edu.vn dưới bất kỳ hình thức nào. Thí sinh không được sử dụng bất kỳ phương tiện nào để can thiệp hoặc cổ vũ việc xâm nhập hệ thống máy chủ của Trường. Nếu thí sinh thấy bất kỳ lỗi nào xảy ra trong quá trình sử dụng, xin vui lòng báo cho Trường qua số điện thoại 08 3835 2020 hoặc email dgnl.hotro@hcmue.edu.vn.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    5.	Thông tin phòng thi
                    </Typography>
                    <ul>
                      <li>Thí sinh cần truy cập vào tài khoản đã đăng ký để xem thông tin phòng thi được Hội đồng thi sắp xếp trước khi đến dự thi. Thí sinh không bắt buộc phải in phiếu dự thi (file PDF trên website). Thông tin cần ghi nhớ là “Phòng thi, giờ thi”; </li>
                      <li>Khi dự thi, thí sinh cần tuân thủ hướng dẫn của cán bộ coi thi trong quá trình làm bài.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    6.	Vật dụng ĐƯỢC mang vào phòng thi
                    </Typography>
                    <ul>
                      <li>Thí sinh PHẢI mang theo Căn cước Công dân bản gốc, không chấp nhận giấy tờ thay thế;</li>
                      <li>Thí sinh được phép mang vào máy tính cầm tay;</li>
                      <li>Thí sinh có thể mang áo khoác;</li>
                      <li>Thẻ giữ đồ do Trường cấp.</li>
                    </ul>                    
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    7.	Vật dụng KHÔNG ĐƯỢC mang vào phòng thi
                    </Typography>
                    <ul>
                      <li>Tất cả các vật dụng khác so với quy định lại [Điều 6] phải được gửi tại quầy Giữ đồ.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    8.	Kết quả thi
                    </Typography>
                    <ul>
                      <li>Trường sẽ công bố kết quả thi của thí sinh theo hình thức phát hành điện tử. Thí sinh có thể tải thông tin kết quả thi về lưu giữ hoặc sử dụng cho các công việc khác;</li>
                      <li>Nếu thí sinh đăng ký xét tuyển các ngành của Trường bằng kết quả thi Đánh giá năng lực thì mặc định điểm thi được chuyển lên hệ thống dữ liệu của Bộ Giáo dục và Đào tạo (Việc chọn ngành, chọn khối đăng ký xét tuyển phải xem các hướng dẫn của các qui định khác của Trường hoặc của Bộ Giáo dục và Đào tạo);</li>
                      <li>Khi chưa hài lòng về kết quả thi, thí sinh có thể đăng ký phúc khảo theo qui định.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontWeight: 'bold',
                        my: 1
                       }}
                    >
                    9.	Chính sách hoàn tiền
                    </Typography>
                    <Typography
                      sx={{ 
                        fontStyle: 'italic',
                       }}
                    >
                    a)	Các trường hợp được hoàn tiền
                    </Typography>
                    <ul>
                      <li>Thí sinh chuyển tiền lệ phí đăng ký dự thi nhưng hệ thống ngân hàng gặp lỗi không thể xác nhận được nguồn tiền được chuyển đến tài khoản ngân hàng của Trường. Sau đó, ngân hàng vẫn chuyển được tiền nhưng đã kết thúc thời gian đăng ký dự thi hoặc đã hết chỗ;</li>
                      <li>Thí sinh điều trị bệnh trong thời gian dài không thể tham gia dự thi (có giấy xác nhận của bệnh viện);</li>
                      <li>Nơi ở hoặc gia đình của thí sinh gặp thiên tai không thể tham gia dự thi.</li>
                    </ul>
                    <Typography
                      sx={{ 
                        fontStyle: 'italic',
                       }}
                    >
                    b)	Các trường hợp không được hoàn tiền
                    </Typography>
                    <ul>
                      <li>Thí sinh tự ý bỏ thi;</li>
                      <li>Thí sinh đi trễ quá thời gian cho phép vào thi;</li>
                      <li>Thí sinh không mang theo căn cước công dân khi dự thi.</li>
                    </ul>
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                  <Button 
                  onClick={ () => {
                    props.setFieldValue("acceptTerms", true);
                    setOpenTC(false);
                  }}
                  color="primary"
                  variant="contained"
                  >
                    Tôi đã đọc và đồng ý các điều khoản trên
                  </Button>
                </DialogActions>
              </Dialog>
              </Form>
              )}
              </Formik>
              </Box>
              <Divider variant="middle" >Nếu bạn đã có tài khoản?</Divider>
              <div className="mt-2">
                  <Button color="primary" fullWidth variant="contained" href="/login">Đăng nhập</Button>
              </div>
            </div>
          )}
        {message && (
          <div className="form-group">
            <div
              className={successful ? "alert alert-success" : "alert alert-danger"}
              role="alert"
            >
              {message}
            </div>
            <div className="mt-2">
                <Button color="primary" fullWidth variant="contained" href="/login">Đăng nhập</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;