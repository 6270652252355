import React , { useState, useEffect  } from "react";
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';

import ExamService from "../../services/exam.service";
import OrderService from "../../services/order.service";

import { useTheme, styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  Paper,
  Alert,
  AlertTitle,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Chip
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    color: ("#cf3439"),
    backgroundColor: "#e5fcff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MoMoButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#d82d8b",
  '&:hover': {
    backgroundColor: "#cb187b",
  },
  borderColor: "000"
}));

const ExamResult = () => {
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { examId } = useParams('');
  const [ exam, setExam ] = useState('')
  const [ user, setUser ] = useState('')
  const [ order, setOrder ] = useState('')
  const [ results, setResults ] = useState('')
  const [ resultsPK, setResultsPK ] = useState('')
  const [ resultId, setResultId ] = useState('')
  const [ status, setStatus ] = useState('')
  const [ link, setLink ] = useState('')
  const [ linkPK, setLinkPK ] = useState('')
  const [ amount, setAmount ] = useState(0)
  const [ width, setWidth ] = useState(window.innerWidth);
  const [ reExam, setReExam ] = useState(false)
  const [ cancelReExam, setCancelReExam ] = useState(false)
  const [ openOrder, setOpenOrder ] = useState(false)
  const [ openCancelOrder, setOpenCancelOrder ] = useState(false)
  // const [ open, setOpen ] = useState('')
  // const [ close, setClose ] = useState('')
  // const [ message, setMessage ] = useState('')
  const [ snackMsg, setSnackMsg ] = useState('');
  const vertical = 'bottom'
  const horizontal = 'center'
  const goToResult = () => navigate('/result/' + examId);
  const goToLogin = () => navigate('/login');
  const goToProfile = () => navigate('/profile');
  const goToPayment = (orderCode) => navigate('/payment/' + orderCode );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      goToLogin()
    }
    ExamService.getExamResults(examId).then(
      (response) => {
        if(!response.data.data.results){
          goToProfile()
        }
        setExam(response.data.data.exam)
        setUser(response.data.data.user)
        setResults(response.data.data.results)
        setResultsPK(response.data.data.reexamResults)
        setStatus(response.data.data.status)
        // setOpen(response.data.data.open)
        // setClose(response.data.data.close)
        setLink(response.data.data.link)
        setLinkPK(response.data.data.reExamLink)
        setAmount(response.data.data.amount)
        setOrder(response.data.data.order)
        // setMessage(response.data.message)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(_content);
      }
    );
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (!isLoggedIn) {
    goToLogin()
  }

  const handleReExamOpen = (rId, isReExam) => () => {
    setResultId(rId)
    if(!isReExam) setReExam(true); 
    else setCancelReExam(true);
  };

  const handleConfirmOrder = () => {
    setOpenOrder(true);
  };

  const handleOpenCancelOrder = () => {
    setOpenCancelOrder(true);
  };

  const handleClose = () => {
    setReExam(false);
    setCancelReExam(false);
    setOpenOrder(false);
    setOpenCancelOrder(false);
  };
  const handleReExam = () => {
    const data = {
      examId: exam.id,
      resultId: resultId
    }
    ExamService.putReExam(data).then( response => {
      goToResult()
      window.location.reload();
    })
  }
  const handleCancelReExam = () => {
    const data = {
      examId: exam.id,
      resultId: resultId
    }
    ExamService.putCancelReExam(data).then( response => {
      goToResult()
      // navigate("/exam");
      window.location.reload();
    })
  }
  const handleOrderPayment = () => {
    OrderService.postReExamOrder({examId: exam.id}).then( response => {
      goToResult()
      window.location.reload();
    })
  };
  const handleCancelReExamOrder = () => {
    OrderService.putCancelReExamOrder({examId: exam.id, code: order.code}).then( response => {
      goToResult()
      window.location.reload();
    })
  }

  return (
    <div>
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardHeader
            title={
              <Typography variant="h5">
                Kết quả dự thi
              </Typography>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent>
          { exam && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid 
                container = { isMobile?false:true } 
                spacing={1}
              >
                <Grid item xs={7} md={7} sx={{ my:1 }}>
                  <Card>
                    <CardContent sx={{ px: 1 }}>
                      <Box sx={{ mx: 2 }}>
                        Kì thi: <Typography variant="subtitle1" component="span" sx={{ fontWeight: "bold", }}>Đợt {exam.turn} tại {exam.location} từ {exam.openAt} đến {exam.closeAt}</Typography><br></br>
                        Thí sinh: <Typography variant="subtitle1" component="span" sx={{ fontWeight: "bold", }}>{ user.fullname }</Typography><br></br>
                        CCCD: <Typography variant="subtitle1" component="span" sx={{ fontWeight: "bold", }}>{ user.cccd }</Typography><br></br>
                        Ngày sinh: <Typography variant="subtitle1" component="span" sx={{ fontWeight: "bold", }}>{ user.birthday }</Typography><br></br>
                      </Box>
                      <Box sx={{ my: 2 }} className="float-start">
                        <Button size="small" color="primary" variant="contained" startIcon={<SendIcon />} href="http://tracuu.hcmue.edu.vn/KS_DGNL_2024" target="_blank">Thí sinh vui lòng làm khảo sát khi xem điểm</Button>
                      </Box>
                      <Box sx={{ my: 2 }} className="float-end">
                        {
                          link && (
                            <Button size="small" color="success" variant="contained" startIcon={<SendIcon />} href={link} target="_blank">Tải Phiếu điểm</Button>
                          )
                        }
                      </Box>
                      <TableContainer 
                        component={Paper}
                        sx={{
                          mt:3
                        }}
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">Môn thi</StyledTableCell>
                              <StyledTableCell align="center">Kết quả</StyledTableCell>
                              {
                                status === "OPEN" && (
                                  <StyledTableCell align="center">Phúc khảo</StyledTableCell>
                                )
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {results.map((row, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {row.subject}
                                </StyledTableCell>
                                <StyledTableCell align="center" sx={{ fontWeight: "bold", }}>{new Intl.NumberFormat().format(row.result)}</StyledTableCell>
                                {
                                  status === "OPEN" && (
                                    <StyledTableCell align="center">
                                      {
                                        !order.code && !row.is_reexam && (
                                          <Button variant="text" size="small" onClick={handleReExamOpen(row.id,row.is_reexam)}>
                                            Đăng ký
                                          </Button>
                                        )
                                      }
                                      {
                                        !order.code && row.status !== "SUCCESS" && row.is_reexam && (
                                          <Button variant="text" color="error" size="small" onClick={handleReExamOpen(row.id,row.is_reexam)}>
                                            Hủy
                                          </Button>
                                        )
                                      }
                                      {
                                        row.status === "SUCCESS" && (
                                          <Chip size="small" variant="outlined" label="Đã thanh toán" color="success" />
                                        )
                                      }
                                      {
                                        row.status === "PENDING" && (
                                          <Chip size="small" variant="outlined" label="Chưa thanh toán" color="warning" />
                                        )
                                      }
                                    </StyledTableCell>
                                  )
                                }
                                {
                                  status !== "OPEN" && row.is_reexam && (
                                    <StyledTableCell align="center">
                                      {
                                        row.status === "SUCCESS" && (
                                          <Chip size="small" variant="outlined" label="Đã thanh toán" color="success" />
                                        )
                                      }
                                      {
                                        row.status === "PENDING" && (
                                          <Chip size="small" variant="outlined" label="Chưa thanh toán" color="warning" />
                                        )
                                      }
                                    </StyledTableCell>
                                  )
                                }
                              </StyledTableRow>
                            ))}
                            {
                              (parseInt(amount) > 0) && (status === "OPEN") && (
                                <StyledTableRow>
                                  <StyledTableCellTotal align="center" colSpan={2}>Lệ phí phúc khảo</StyledTableCellTotal>
                                  <StyledTableCellTotal align="center">{new Intl.NumberFormat().format( amount )}đ</StyledTableCellTotal>
                                </StyledTableRow>
                              )
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* ket qua phuc khao */}
                      {
                        resultsPK && (
                          <>
                          {/* <Divider variant="middle" sx={{ mt:3, border: '1px solid', borderColor: 'text.primary',}} /> */}
                          <TableContainer 
                            component={Paper}
                            sx={{
                              mt:3
                            }}
                          >
                            <Table aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="center">Môn thi</StyledTableCell>
                                  <StyledTableCell align="center">Kết quả PK</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {resultsPK.map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="center">
                                      {row.subject}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontWeight: "bold", }}>{new Intl.NumberFormat().format(row.result)}</StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Box sx={{ my: 2 }} className="float-end">
                            {
                              linkPK && (
                                <Button size="small" color="warning" variant="contained" startIcon={<SendIcon />} href={linkPK} target="_blank">Tải Phiếu điểm PK</Button>
                              )
                            }
                          </Box>
                          </>
                        )
                      }
                    </CardContent>
                    <CardActions className="float-end">
                      <ButtonGroup variant="text" aria-label="text button group">
                        {
                          order.status === "PENDING" && (
                            <Button size="small" color="error" onClick={ handleOpenCancelOrder }>Huỷ đăng ký</Button>
                          )
                        }
                      </ButtonGroup>
                    </CardActions>
                  </Card>
                </Grid>
                {
                  (parseInt(amount) > 0) && (status === "OPEN") && (
                    <Grid item xs={5} md={5} sx={{ mt: 1 }}>
                      <Card>
                        <CardContent>
                        {
                          !order.code && (
                            <Stack spacing={2} direction="column">
                              <Button variant="contained" color="warning" onClick={handleConfirmOrder}>Thanh toán lệ phí phúc khảo</Button>
                            </Stack>
                          )
                        }
                        {
                          order.code && (
                            <Box
                              component="img"
                              alt="VietQR"
                              src= { 'https://img.vietqr.io/image/VCB-1900668888-yxa4dtq.png?amount=' + amount + '&addInfo=DGPK' + order.code + ' ' + currentUser.cccd + '&accountName=TRUONG DAI HOC SU PHAM TPHCM' }
                              sx={{ width: 9.5/10 }}
                            />
                          )
                        }
                        <Alert severity="warning" sx={{ mt:1, mx: 1 }}>
                          <Typography component="div" variant="body">
                            Lưu ý: Lệ phí phúc khảo sau khi thanh toán sẽ không được hoàn trả lại trong mọi trường hợp!
                          </Typography>
                        </Alert>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                }
              </Grid>
              <Dialog
                open={reExam}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bạn muốn Đăng ký phúc khảo?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Hệ thống sẽ ghi nhận yêu cầu đăng ký phúc khảo của bạn. <br></br>
                    Nếu bạn chưa chắc chắn, hãy nhấn Quay lại.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Quay lại</Button>
                  <Button onClick={handleReExam} color="error" autoFocus>
                    Đăng ký
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={cancelReExam}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bạn muốn Hủy đăng ký phúc khảo?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Hệ thống sẽ hủy yêu cầu đăng ký phúc khảo của bạn. <br></br>
                    Nếu bạn chưa chắc chắn, hãy nhấn Quay lại.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Quay lại</Button>
                  <Button onClick={handleCancelReExam} color="error" autoFocus>
                    Hủy đăng ký
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={ openOrder }
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bạn muốn Thanh toán lệ phí phúc khảo?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Hệ thống sẽ ghi nhận các yêu cầu đăng ký phúc khảo và bạn sẽ không thể thay đổi yêu cầu sau khi đã XÁC NHẬN. <br></br>
                    Nếu bạn chưa chắc chắn, hãy nhấn Quay lại.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Quay lại</Button>
                  <Button onClick={handleOrderPayment} color="error" autoFocus>
                    XÁC NHẬN
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openCancelOrder}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bạn muốn hủy đăng ký phúc khảo?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tất cả yêu cầu đăng ký phúc khảo của bạn sẽ bị hủy!<br></br>
                    Nếu bạn chưa chắc chắn, hãy nhấn Quay lại.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Quay lại</Button>
                  <Button onClick={handleCancelReExamOrder} color="error" autoFocus>
                    HỦY ĐĂNG KÝ
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
          </CardContent>
          <CardActions className="float-end">
          </CardActions>
        </Card>
        </Paper>
      </Box>
    </div>
  );
};

export default ExamResult;