// import React from "react";
import { useState, useEffect, useRef  } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { 
  // useDispatch, 
  useSelector 
} from "react-redux";
import dayjs from 'dayjs';

import { createTheme } from '@mui/material/styles';
import { green, pink } from '@mui/material/colors';

// import UserService from "../../services/user.service";
import ExamService from "../../services/exam.service";
import OrderService from "../../services/order.service";

import { 
  // useTheme, 
  styled 
} from '@mui/material/styles';
import {
  Grid,
  Box,
  Paper,
  Alert,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  // IconButton,
  Stack,
  Chip,
  // OutlinedInput,
  MenuItem,
  FormControl,
  List,
  ListItem,
  // ListItemIcon,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { ConstructionOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    color: ("#cf3439"),
    backgroundColor: "#e5fcff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const theme = createTheme({
  date: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    time: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function compareNumbers(a, b) {
  return a - b;
}

const subjectName = ["","Toán học","Vật lí","Hóa học","Sinh học","Ngữ văn","Tiếng Anh"]

const ExamBookedDashboard = () => {
  // const theme = useTheme();
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  // const { orderCode } = useSelector((state) => state.order);
  // const dispatch = useDispatch();
  const descriptionElementRef = useRef(null);
  const [orderCode, setOrderCode] = useState('');
  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState('');
  const [turns, setTurns] = useState([]);
  const [selectedTurns, setSelectedTurns] = useState([]);
  const [bookedTurns, setBookedTurns] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [codeArr, setCodeArr] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const goToProfile = () => navigate('/profile');

  useEffect(() => {
    if (isLoggedIn) {
      if(!(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_SUPER_ADMIN"))){
        goToProfile()
      }else{
        ExamService.getExams().then(
          (response) => {
            setExams(response.data.data);
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
              console.log(_content);
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleChangeExam = async (event) => {
    setExam(event.target.value);
    let response = await OrderService.getNewOrderCode({examId: event.target.value.id})
    setOrderCode(response.data.data)

    ExamService.getAllBookedTurnsByExam({examId: event.target.value.id}).then(
      (response) => {
        setTurns(response.data.data)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          console.log(_content);
      }
    );
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardHeader
            title={
              <Typography variant="h5">
                Thống kê thí sinh
            </Typography>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent 
            sx={{ 
              display: 'grid', 
              gridAutoColumns: '1fr',
              gap: 1,
            }}
          >
            {exams.length > 0 && (
              <>
              <FormControl 
                sx={{ 
                  '& label': { mt: 0 }, 
                  m: 1 , 
                  minWidth: 120
                }}
              >
                <InputLabel id="select-label">Chọn Kì thi</InputLabel>
                <Select
                  labelId="select-label"
                  id="exam-select"
                  label="Chọn kì thi"
                  value={exam}
                  onChange={handleChangeExam}
                >
                {exams.map((item, index) => (
                  <MenuItem
                    key={ index }
                    value={item}
                  >
                    Đợt {item.turn} tại {item.location} từ {item.openAt} đến {item.closeAt} { item.msg }
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <Divider />
              {
                exam && turns.length > 0 && (
                  < >
                  <Grid item xs={12}>
                    <Box sx={{ my: 2 }} className="float-end">
                      <Stack spacing={2} direction="row">
                        <Button size="small" color="warning" variant="contained" href={ process.env.REACT_APP_API_URL + "api/export/candidates/" + exam.id } target="_blank">Xuất DS thí sinh</Button>
                        <Button size="small" color="primary" variant="contained" href={ process.env.REACT_APP_API_URL + "api/export/reexam/" + exam.id } target="_blank">Xuất DS thí sinh PK</Button>
                        <Button size="small" color="success" variant="contained" href={ process.env.REACT_APP_API_URL + "api/export/payment/" + exam.id } target="_blank">Xuất thanh toán</Button>
                      </Stack>
                    </Box>
                    <Grid 
                      container 
                      justifyContent="center" 
                      spacing={1} 
                    >
                      {turns.map((turn, index) => {
                        if(turn.visible > 0) return (
                        <Grid key={ index } item>
                          <Card
                            sx={{
                              // minHeight: 340,
                              backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            }}
                          >
                            <CardHeader
                              title={
                                <>
                                  <Typography component="div" variant="h6">
                                    {/* Ca {turn.turn} */}
                                    <Typography component="div" size="small" color="text.secondary">
                                      Ngày thi: <Typography sx={{ fontWeight: 'bold', color: '#054675' }} component="span">{turn.date}</Typography>
                                    </Typography>
                                    <Typography component="div" size="small" color="text.secondary">
                                      Giờ thi: <Typography sx={{ fontWeight: 'bold', color: '#cf3439' }} component="span">{turn.time}</Typography>
                                    </Typography>
                                  </Typography>
                                </>
                              }
                            />
                            <CardContent>
                              <List component="nav" aria-label="secondary mailbox folder">
                              {turn.subjects.map((subject, index) => {
                                if(subject.maxSlots > 0) return (
                                  // <ListItem
                                  //   key={ index }
                                  //   secondaryAction={
                                  //     <Chip sx={{ width: 75 }} edge="end" size="small" variant="outlined" color={ (subject.availableSlots >= subject.maxSlots)?"error":"success" } icon={<GroupIcon />} label={ (subject.availableSlots >= subject.maxSlots)?"Full":"Còn" } />
                                  //   }
                                  // >
                                  //   <ListItemButton
                                  //     key={ index }
                                  //   >
                                  //     <ListItemText primary= { subjectName[subject.id] } />
                                  //     <Chip sx={{ width: 55 }} edge="end" size="small" variant="outlined" color="success" icon={<GroupIcon />} label={ subject.availableSlots + "/" + subject.maxSlots } />
                                  //   </ListItemButton>
                                  // </ListItem>
                                  <ListItem>
                                    <ListItemAvatar>
                                      {/* <Chip 
                                        size="small" 
                                        variant="outlined" 
                                        color={ (subject.availableSlots >= subject.maxSlots)?"error":"success" } 
                                        icon={<GroupIcon />} 
                                      /> */}
                                      {
                                        (subject.availableSlots >= subject.maxSlots) && (
                                          <Avatar sx={{ bgcolor: pink[500], width: 45, height: 45 }}>
                                            {/* <AccountCircleIcon /> */}
                                            {subject.availableSlots}
                                          </Avatar>
                                        )
                                      }
                                      {
                                        (subject.availableSlots < subject.maxSlots) && (
                                          <Avatar sx={{ bgcolor: green[500], width: 45, height: 45 }}>
                                            {/* <AccountCircleIcon /> */}
                                            {subject.availableSlots}
                                          </Avatar>
                                        )
                                      }
                                    </ListItemAvatar>
                                    <ListItemText primary={ subjectName[subject.id] } secondary={ subject.availableSlots + "/" + subject.maxSlots } />
                                  </ListItem>
                                )
                              } )}
                              </List>
                            </CardContent>
                          </Card>
                        </Grid>
                      )})}
                    </Grid>
                  </Grid>
                  </>
                )
              }
              </>
            )}
            {exams.length === 0 && (
              <Alert severity="warning">
                <Typography variant="body">
                  Chưa có thông tin Kì thi
                </Typography>
              </Alert>
            )}
          </CardContent>
          <CardActions 
            className="float-end"
            sx={{
              mb: 1, mr: 2
            }}
          >
          </CardActions>
        </Card>
        </Paper>
      </Box>
    </div>
  );
};

export default ExamBookedDashboard;