import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "api/auth/"

const signup = (fullname, email, password) => {
    return axios.post(API_URL + "signup", {
        fullname,
        email,
        password,
    });
};

const register = (fullname, cccd, birthday, gender, phone, email, password, acceptTerms) => {
    return axios.post(API_URL + "register", {
        fullname,
        cccd,
        gender,
        birthday,
        phone,
        email,
        password,
        acceptTerms
    });
};
  
const login = (cccd, password) => {
    return axios
        .post(API_URL + "signin", {
        cccd,
        password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
                // localStorage.setItem("orderCode", "V200011");
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("selectedTurns");
    localStorage.removeItem("orderCode");
};
  
const authService = {
    signup,
    register,
    login,
    logout,
};
  
export default authService;