// Layouts
import LoginLayout from "../layouts/LoginLayout";
import AppLayout from "../layouts/AppLayout";

// Pages
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import Search from "../components/public/Search";

import Profile from "../components/user/Profile";
import ExamResult from "../components/user/ExamResult";

import ExamEnrollment from "../components/exam/Enrollment";
import PaymentDetail from "../components/payment/PaymentDetail";
import PaymentReturn from "../components/payment/PaymentReturn";

//admin
import DashboardExam from "../components/admin/DashboardExam";

import renderRoutes from "./generate-routes";

export const routes = [
  {
    layout: LoginLayout,
    routes: [
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        path: '/',
        isPublic: true,
      },
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        path: '/login',
        isPublic: true,
      },
      {
        name: 'register',
        title: 'Register page',
        component: Register,
        path: '/register',
        isPublic: true,
      },
      {
        name: 'search',
        title: 'Search page',
        component: Search,
        path: '/ketqua',
        isPublic: true,
      }
    ]
  },
  {
    layout: AppLayout,
    routes: [
      {
        name: 'profile',
        title: 'Profile page',
        component: Profile,
        path: '/profile'
      },
      {
        name: 'enrollment',
        title: 'Exam Enrollment page',
        component: ExamEnrollment,
        path: '/exam'
      },
      {
        name: 'examResult',
        title: 'Exam Result page',
        component: ExamResult,
        path: '/result/:examId'
      },
      {
        name: 'payment',
        title: 'Payment page',
        component: PaymentDetail,
        path: '/payment/:type/:orderCode'
      },
      {
        name: 'MoMo',
        title: 'MoMo return page',
        component: PaymentReturn,
        path: '/payment/return'
      },
      {
        name: 'dashboard',
        title: 'View Booked Data',
        component: DashboardExam,
        path: '/dashboard'
      },
      {
        name: 'any',
        title: 'Profile page',
        component: Profile,
        path: '*'
      },
    ]
  }
];

// Just add this line
export const Routes = renderRoutes(routes);