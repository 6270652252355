import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

import { 
    // Paper,
    Box,
    // Grid,
    // Typography,
    // FormControlLabel,
    // Checkbox,
    Button, 
    TextField,
    InputAdornment,
    IconButton,
    Divider 
} from '@mui/material';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material'

import { _login } from "../../slices/auth";
import { clearMessage } from "../../slices/message";

const Login = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    if (isLoggedIn) {
        return <Navigate to="/profile" />
    }

    const initialValues = {
        cccd: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        cccd: Yup.string().required("Thông tin này không được bỏ trống!"),
        password: Yup.string().required("Thông tin này không được bỏ trống!"),
    });

    const handleLogin = (formValue) => {
        // alert(JSON.stringify(formValue, null, 2));
        const { cccd, password } = formValue;
        setLoading(true);

        dispatch(_login({ cccd, password }))
            .unwrap()
            .then(() => {
                navigate("/profile");
                // navigate("/exam");
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
    <div className="col-md-12 login-form">
        <div className="card card-container">
        <img
            src= "/logo_hcmue.png"
            alt="profile-img"
            className="profile-img-card"
        />
        <Box
            sx={{
            '& .MuiTextField-root': { py: 1 },
            mb: 2
            }}
            noValidate
            autoComplete="off"
        >
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
        >
            {props => (
            <Form>
                <TextField
                    fullWidth
                    id="cccd"
                    name="cccd"
                    label="Số Căn cước công dân"
                    variant="standard"
                    value={props.values.cccd}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.cccd && Boolean(props.errors.cccd)}
                    helperText={props.touched.cccd && props.errors.cccd}
                    autoComplete="off"
                />
                <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Mật khẩu"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.password && Boolean(props.errors.password)}
                    helperText={props.touched.password && props.errors.password}
                    autoComplete="off"
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    ),
                    }}
                />
                <Button color="primary" fullWidth variant="contained" size="large" type="submit" sx={{ mt:1 }}>Đăng nhập</Button>
            </Form>
            )}
        </Formik>
        {message && (
        <div className="form-group mt-3">
            <div className="alert alert-danger" role="alert">
            {message}
            </div>
        </div>
        )}
        </Box>
        <Divider variant="middle">Nếu bạn chưa có tài khoản?</Divider>
        <div className="mt-2">
            <Button color="success" fullWidth variant="contained" href="/register">Đăng ký tài khoản mới</Button>
        </div>
        </div>
    </div>
    );
};

export default Login;