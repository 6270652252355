import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + "api/order/"

const getNewOrderCode = (data) => {
    return axios.get(API_URL + "code/" + data.examId, { headers: authHeader() });
};

const getOrderDetails = (data) => {
    return axios.get(API_URL + "details/" + data.code, { headers: authHeader() });
}

const postOrderDetails = (data) => {
    return axios.post(API_URL + "details", data, { headers: authHeader() });
}

const putCancelOrder = (data) => {
    return axios.put(API_URL + "cancel", data, { headers: authHeader() });
}

const postReExamOrder = (data) => {
    return axios.post(API_URL + "re-exam", data, { headers: authHeader() });
}

const putCancelReExamOrder = (data) => {
    return axios.put(API_URL + "cancel-re-exam", data, { headers: authHeader() });
}

const orderService = {
    getNewOrderCode,
    getOrderDetails,
    postOrderDetails,
    putCancelOrder,
    postReExamOrder,
    putCancelReExamOrder
};
  
export default orderService