import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + "api/"

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getSearchResult = (cccd) => {
    return axios.get(API_URL + "search/" + cccd);
}

const userService = {
    getPublicContent,
    getSearchResult,
};
  
export default userService