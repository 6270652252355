import React , { useState, useEffect  } from "react";
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';

import PaymentService from "../../services/payment.service";
import OrderService from "../../services/order.service";

import { useTheme, styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  Paper,
  Alert,
  AlertTitle,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    color: ("#cf3439"),
    backgroundColor: "#e5fcff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// const MoMoButton = styled(Button)(({ theme }) => ({
//   color: "#fff",
//   backgroundColor: "#d82d8b",
//   '&:hover': {
//     backgroundColor: "#cb187b",
//   },
//   borderColor: "000"
// }));

const PaymentDetail = () => {
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  // const { orderCode } = useSelector((state) => state.order);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderCode, type } = useParams('');
  const [ exam, setExam ] = useState('')
  const [ orderDate, setOrderDate ] = useState('')
  const [ expireDate, setExpireDate ] = useState('')
  const [ orderDetails, setOrderDetails ] = useState('')
  const [ totalPrice, setTotalPrice ] = useState(0)
  const [ paymentInfo, setPaymentInfo ] = useState(null)
  const [ paymentGateway, setPaymentGateway ] = useState('')
  const [ paymentAt, setPaymentAt ] = useState('')
  const [ status, setStatus ] = useState('')
  const [ message, setMessage ] = useState(null);
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ cancelDialog, setCancelDialog ] = useState(false);
  const [ width, setWidth ] = useState(window.innerWidth);
  const [ checkBankingSnack, setCheckBankingSnack ] = useState(false);
  const [ snackMsg, setSnackMsg ] = useState('');
  const vertical = 'bottom'
  const horizontal = 'center'

  const goToProfile = () => navigate('/profile');
  const goToExam = () => navigate('/exam');
  const goToLogin = () => navigate('/login');
  const goToPayment = () => navigate('/payment/' + orderCode );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // console.log(orderCode)
    if (!isLoggedIn) {
      goToLogin()
    }
    if (!orderCode || orderCode === '') {
      goToProfile()
    }
    OrderService.getOrderDetails({code: orderCode}).then(
      (response) => {
        if(response.data.status === "CANCEL" || response.data.status === "EXPIRED"){
          goToProfile()
        }
        setExam(response.data.exam)
        setOrderDate(response.data.createdAt)
        setExpireDate(response.data.expireAt)
        setTotalPrice(response.data.totalPrice)
        setOrderDetails(response.data.turns)
        setPaymentInfo(response.data.paymentInfo)
        setPaymentGateway(response.data.paymentGateway)
        setPaymentAt(response.data.paymentAt)
        setStatus(response.data.status)
        setMessage(response.data.message)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(_content);
      }
    );
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (!isLoggedIn) {
    goToLogin()
  }

  const handleVietQROpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    handleCheckBankingClick()
  };

  const handleOpenCancel = () => {
    setCancelDialog(true);
  };

  const handleCloseCancel = (event, reason) => {
    setCancelDialog(false)
  };

  const handleMoMoPayment = () => {
    PaymentService.postMomoRequest({
      orderCode: orderCode,
      totalPrice: totalPrice,
      type: type
    }).then( res => {
      window.location.href = res.data.payUrl
    })
  }

  const handleCancelOrder = () => {
    OrderService.putCancelOrder({
      orderCode: orderCode
    }).then( res => {
      if(res.data.count) goToProfile()
    })
  }

  const handleCheckBankingClick = () => {
    setCheckBankingSnack(true)
    setSnackMsg("Đang kiểm tra thông tin. Vui lòng chờ trong giây lát ...")
    PaymentService.getBankingPaymentByCode({
      code: orderCode
    }).then( res => {
      if(res.data.amount > 0){
        window.location.reload()
      }
      // else{
      //   setCheckBankingSnack(false)
      //   setOpenDialog(false);
      // }
    })
  };

  const handleCheckBankingClose = () => {
    setCheckBankingSnack(false)
    setOpenDialog(false);
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardHeader
            title={
              <Typography variant="h5">
                Thanh toán lệ phí
              </Typography>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent>
          { exam && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid 
                container = { isMobile?false:true } 
                spacing={1}
              >
                <Grid item xs={8} md={8}>
                  <Card>
                    <CardContent sx={{ px: 0 }}>
                      <Box sx={{ mx: 2 }}>
                        <Typography variant="subtitle1" component="div" sx={{ fontWeight: "bold", }}>Kì thi: Đợt {exam.turn} tại {exam.location} từ {exam.openAt} đến {exam.closeAt}</Typography>
                        <Typography variant="subtitle1" component="div" className="text-primary">Mã đăng ký: {orderCode}</Typography>
                        <Typography variant="subtitle1" component="div">Ngày đăng ký: { orderDate }</Typography>
                        { status === "PENDING" && (
                          <Typography variant="subtitle1" component="div">Hạn thanh toán: { expireDate }</Typography>
                        )}
                        { status === "SUCCESS" && (
                          <Alert severity="success">
                            <AlertTitle>Đã thanh toán</AlertTitle>
                            Ngày thanh toán: { paymentAt } <br></br>
                            Cổng thanh toán: { paymentGateway }
                          </Alert>
                        )}
                      </Box>
                      <TableContainer 
                        component={Paper}
                        sx={{
                          mt:3
                        }}
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">Môn thi</StyledTableCell>
                              <StyledTableCell align="right">Lệ phí</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails.map((row, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell>
                                  {row.subject}<br></br>
                                  {row.date}<br></br>
                                  {row.time}
                                </StyledTableCell>
                                <StyledTableCell align="right">{new Intl.NumberFormat().format(row.price)}đ</StyledTableCell>
                              </StyledTableRow>
                            ))}
                            <StyledTableRow>
                              <StyledTableCellTotal align="center">Tổng lệ phí</StyledTableCellTotal>
                              <StyledTableCellTotal align="right">{new Intl.NumberFormat().format( totalPrice )}đ</StyledTableCellTotal>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      { status === "PENDING" && (
                      <Alert severity="warning" sx={{ mt:1, mx: 1 }}>
                        <Typography component="div" variant="body">
                          Lưu ý: bạn cần hoàn tất việc thanh toán lệ phí trong vòng 24 giờ tính từ ngày đăng ký! Hệ thống sẽ tự động cập nhật kết quả thanh toán.
                        </Typography>
                        <Typography component="div" variant="body">
                          Nếu kết quả thanh toán chưa được cập nhật sau 24 giờ tính từ thời điểm giao dịch thành công, vui lòng gửi thư điện tử về email dgnl.hotro@hcmue.edu.vn để được hỗ trợ.
                        </Typography>
                      </Alert>
                      )}
                    </CardContent>
                    <CardActions className="float-end">
                      <ButtonGroup variant="text" aria-label="text button group">
                        {
                          status === "PENDING" && (
                            <Button size="small" color="error" onClick={ handleOpenCancel }>Huỷ đăng ký</Button>
                          )
                        }
                      </ButtonGroup>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={4} md={4} sx={{ mt: 1 }}>
                  {
                    status === "PENDING" && (
                      <Card>
                        <CardContent>
                          <Stack spacing={2} direction="column">
                            <Button variant="contained" color="primary" onClick={handleVietQROpen}>Thanh toán QR Code</Button>
                            {/* <MoMoButton variant="outlined" onClick={handleMoMoPayment}>Thanh toán MoMo</MoMoButton> */}
                          </Stack>
                        </CardContent>
                      </Card>
                    )
                  }
                </Grid>
              </Grid>
              <Dialog 
                open={ openDialog }
                onClose={ handleClose }
              >
                <DialogContent>
                    <Box
                      component="img"
                      alt="VietQR"
                      src= { 'https://img.vietqr.io/image/VCB-1900668888-yxa4dtq.png?amount=' + totalPrice + '&addInfo=' + type + orderCode + ' ' + currentUser.cccd + '&accountName=TRUONG DAI HOC SU PHAM TPHCM' }
                      sx={{ width: 9.5/10 }}
                    />
                </DialogContent>
                <DialogActions 
                  sx={{ px:2.5}}
                  style={{ justifyContent: 'center' }}
                >
                    <Button variant="outlined" onClick={handleClose}>Tôi đã chuyển khoản đủ tiền</Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={cancelDialog}
                onClose={handleCloseCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bạn có muốn hủy đăng ký?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tất cả môn thi bạn đăng ký với mã {orderCode} sẽ bị hủy!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseCancel}>Quay lại</Button>
                  <Button color="error" onClick={handleCancelOrder} autoFocus>
                    Hủy đăng ký
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
          </CardContent>
          <CardActions className="float-end">
          </CardActions>
        </Card>
        </Paper>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={checkBankingSnack}
        onClose={handleCheckBankingClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCheckBankingClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          { snackMsg }
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PaymentDetail;