import React , { useState, useEffect  } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';

import UserService from "../../services/user.service";

import {
  Box,
  Grid,
  Paper,
  Alert,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  IconButton,
  Stack,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 14
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Profile = () => {
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [orders, setOrders] = useState('');
  const [width, setWidth] = useState(window.innerWidth);

  const goToProfile = () => navigate('/profile');
  const goToExam = () => navigate('/exam');
  const goToLogin = () => navigate('/login');
  const goToDashboard = () => navigate('/dashboard');

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (isLoggedIn) {
      UserService.getUserInfo().then(
        (response) => {
          setInfo(response.data.data);
          if(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("ROLE_SUPER_ADMIN")){
            goToDashboard()
          }else{
            UserService.getUserTurns().then(
              res => {
                if(res.data.data.length === 0){
                  goToExam()
                }
                setOrders(res.data.data)
              }
            )
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
            setInfo(_content);
        }
      );
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (!isLoggedIn) {
    goToLogin()
  }

  const isClosed = (date) => {
    const _close = date.split('/')
    if(_close.length != 3) return false;
    const closeAt = new Date(parseInt(_close[2]),parseInt(_close[1])-1,parseInt(_close[0]),23,59,59)
    if(Date.now() > closeAt.getTime()) return true
    return false
  }
  
  return (
    <div>
      {info && (
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0)',
            width: 9/10
          }}
        >
        {/* {info && ( */}
        <Card 
          variant="outlined"
        >
          <CardHeader
            // action={
            //   <IconButton aria-label="settings" color="primary">
            //     <SettingsIcon fontSize="inherit" />
            //   </IconButton>
            // }
            title={
              <Typography variant="h6">
                Thông tin tài khoản 
                {/* <IconButton aria-label="settings" color="warning">
                  <SettingsIcon fontSize="inherit" />
                </IconButton> */}
              </Typography>
            }
            subheader={
              <div>
              <Typography>Ngày đăng ký: {dayjs(info.createdAt).format('DD/MM/YYYY hh:mm:ss')}</Typography>
              {/* <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
                <Chip size="small" variant="outlined" label="Chưa xác thực email" color="error" />
                <Chip size="small" variant="outlined" label="Chưa xác thực CCCD" color="error" />
              </Stack> */}
              </div>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent 
            // sx={{ 
            //   display: 'grid', 
            //   gridAutoColumns: '1fr',
            //   gap: 1,
            // }}
          >
          <Typography 
            component="p" 
            variant="body" 
            sx={{ gridRow: '1', gridColumn: 'span 3' }}
          >
            Họ và tên: {info.fullname}
          </Typography>
          <Typography 
            component="p" 
            variant="body" 
            sx={{ gridRow: '2', gridColumn: '1/3' }}
          >
            Số CCCD: {info.cccd}
          </Typography>
          {/* <Typography 
            component="p" 
            variant="body" 
            sx={{ gridRow: '2', gridColumn: '2/3' }}
          >
            Ngày cấp: 
            { info.birthplace ? 
            info.birthplace 
            : 
            <Typography variant="body" color="text.secondary" size="small"> Chưa cập nhật </Typography>
            }
          </Typography>
            <Typography component="p" variant="body" sx={{ gridRow: '2', gridColumn: '3/3' }}>
              Nơi cấp: 
              { info.birthplace ? 
              info.birthplace 
              : 
              <Typography variant="body" color="text.secondary" size="small"> Chưa cập nhật </Typography>
              }
            </Typography> */}
            <Typography component="p" variant="body" sx={{ gridRow: '3', gridColumn: '1/3' }}>
              Ngày sinh: {info.birthday}
            </Typography>
            {/* <Typography component="p" variant="body" sx={{ gridRow: '3', gridColumn: '2/3' }}>
              Nơi sinh: 
              { info.birthplace ? 
              info.birthplace 
              : 
              <Typography variant="body" color="text.secondary" size="small"> Chưa cập nhật </Typography>
              }
            </Typography> */}
            <Typography component="p" variant="body" sx={{ gridRow: '4', gridColumn: '1/3' }}>
              Số điện thoại: {info.phone}
            </Typography>
            <Typography component="p" variant="body" sx={{ gridRow: '4', gridColumn: '2/3' }}>
              Email: 
              { info.email!=="noemail@mail.com" ? 
              info.email 
              : 
              <Typography variant="body" color="text.secondary" size="small"> Chưa cập nhật </Typography>
              }
            </Typography>
            {/* <Typography component="div" variant="body" sx={{ gridRow: '5', gridColumn: 'span 3' }}>
              Địa chỉ liên lạc:
              { info.birthplace ? 
              info.birthplace 
              : 
              <Typography variant="body" color="text.secondary" size="small"> Chưa cập nhật </Typography>
              }
            </Typography> */}
          </CardContent>
          <CardActions className="float-end">
            {/* <ButtonGroup variant="outlined" aria-label="text button group">
              <Button size="small" color="warning">Xác thực CCCD</Button>
              <Button size="small">Xác thực Email</Button>
            </ButtonGroup> */}
          </CardActions>
        </Card>
        {/* )} */}
        </Paper>
      </Box>
      )}

    {orders && (
      <Box
        sx={{
          borderRadius: 0.5,
          mt: 1
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardHeader
            title={
              <Typography variant="h6">
                Thông tin đăng ký dự thi  {process.env.REACT_APP_SERVICE_NAME} năm {new Date().getFullYear()}
              </Typography>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent>
          { (orders.length === 0) && (
            <Alert severity="warning">
            <Typography variant="body">
              Chưa đăng ký dự thi
            </Typography>
          </Alert>
          )}
          {orders.map((row, _index) => (
            <Box sx={{ flexGrow: 1 }} key={_index}>
            <Grid 
              container = { isMobile?false:true } 
              // spacing={2}
            >
              <Grid item xs={12} md={12}>
              <Card>
                <CardHeader
                  sx={{ pb: 0 }}
                  title={
                    <Typography variant="subtitle1" component="div" sx={{ fontWeight: "bold", }}>Kì thi: Đợt {row.exam.turn} tại {row.exam.location} từ {row.exam.openAt} đến {row.exam.closeAt}</Typography>
                  }
                />
                <CardContent sx={{ p: 0 }}>
                  <TableContainer 
                    component={Paper}
                    sx={{
                      mt:3
                    }}
                  >
                    <Table aria-label="customized table">
                      <TableHead>
                      { !isMobile && (
                        <TableRow>
                          <StyledTableCell align="center">Môn thi</StyledTableCell>
                          <StyledTableCell align="center">Phòng thi</StyledTableCell>
                          <StyledTableCell align="center">Mã đăng ký</StyledTableCell>
                          <StyledTableCell align="center">Ngày đăng ký</StyledTableCell>
                          <StyledTableCell align="center">Trạng thái</StyledTableCell>
                        </TableRow>
                        )
                      }
                      { isMobile && (
                        <TableRow>
                          <StyledTableCell align="center">Môn thi</StyledTableCell>
                          <StyledTableCell align="center">Phòng thi</StyledTableCell>
                          <StyledTableCell align="center">Mã đ.ký</StyledTableCell>
                        </TableRow>
                        )
                      }
                      </TableHead>
                      { !isMobile && (
                      <TableBody>
                        {row.details.map((detail, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {detail.subject}<br></br>
                              {detail.date} - {detail.time}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {detail.room}
                              {/* {
                                () => {
                                  if(detail.room !=="Chưa có") {return (<Typography component="span" sx={{ fontWeight: 'bold', color: '#cf3439' }}>detail.room</Typography>)}
                                  else {return (<Typography component="span" color="text.secondary">Chưa có</Typography>)}
                                }
                              } */}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Button variant="text" color="primary" href={ "/payment/" + process.env.REACT_APP_SERVICE + "/" + detail.orderCode }>{ detail.orderCode }</Button>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              { detail.orderedAt }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Chip size="small" variant="outlined" label={ (detail.status === "SUCCESS") ? "Đã thanh toán" : "Chờ thanh toán"} color={ (detail.status === "SUCCESS") ? "success" : "warning"} />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                        )
                      }
                      { isMobile && (
                        <TableBody>
                        {row.details.map((detail, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {detail.subject}<br></br>
                              {detail.date}<br></br>{detail.time}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {detail.room}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Button variant="text" color="primary" href={ "/payment/" + process.env.REACT_APP_SERVICE + "/" + detail.orderCode }>{ detail.orderCode }</Button>
                              <Chip size="small" variant="outlined" label={ (detail.status === "SUCCESS") ? "Đã th.toán" : "Chờ th.toán"} color={ (detail.status === "SUCCESS") ? "success" : "warning"} />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                        )
                      }
                    </Table>
                  </TableContainer>                  
                </CardContent>
                <CardActions style={{ justifyContent: 'right' }}>
                  {
                    !isClosed(row.exam.closeAt) && row.isTicket && (
                      <Button size="small" variant="contained" startIcon={<SendIcon />} href={process.env.REACT_APP_API_URL + "api/export/exam-ticket/" + currentUser.id + "/" + row.exam.id}>Tải phiếu dự thi</Button>
                    )
                  }
                  {
                    isClosed(row.exam.closeAt) && row.isResult && (
                      <Button size="small" variant="contained" color="warning" startIcon={<CheckIcon />} href={"result/" + row.exam.id}>Xem kết quả</Button>
                    )
                  }
                </CardActions>
              </Card>
              </Grid>
            </Grid>
            </Box>
          ))}
          { (orders.length > 0) && (
            <Alert severity="info" sx={{ mt:1 }}>
              <Typography variant="caption">
                Nhấn vào Mã đăng ký để xem chi tiết và thanh toán
              </Typography>
          </Alert>
          )}
          </CardContent>
        </Card>
        </Paper>
      </Box>
      )}
    </div>
  );
};

export default Profile;