import { createSlice } from "@reduxjs/toolkit";

const code = localStorage.getItem("orderCode")

const initialState = {
    orderCode: code ? code : "000"
};

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
    setOrderCode: (state, action) => {
        return { orderCode: action.payload };
    },
    clearOrderCode: () => {
        return { orderCode: "" };
    },
    },
});

const { reducer, actions } = orderSlice;

export const { setOrderCode, clearOrderCode } = actions
export default reducer;