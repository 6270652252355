import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + "api/"

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getUserBoard = () => {
    return axios.get(API_URL + "test/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
    return axios.get(API_URL + "test/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
    return axios.get(API_URL + "test/admin", { headers: authHeader() });
};

const getUserInfo = () => {
    return axios.get(API_URL + "user", { headers: authHeader() });
};

const getUserTurns = () => {
    return axios.get(API_URL + "user/exams", { headers: authHeader() });
};

const userService = {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    getUserInfo,
    getUserTurns,
};
  
export default userService