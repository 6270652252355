// import React from "react";
import { useState, useEffect, useRef  } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { 
  // useDispatch, 
  useSelector 
} from "react-redux";
import dayjs from 'dayjs';

import { createTheme } from '@mui/material/styles';

// import UserService from "../../services/user.service";
import ExamService from "../../services/exam.service";
import OrderService from "../../services/order.service";

import { 
  // useTheme, 
  styled 
} from '@mui/material/styles';
import {
  Grid,
  Box,
  Paper,
  Alert,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  // IconButton,
  Stack,
  Chip,
  // OutlinedInput,
  MenuItem,
  FormControl,
  List,
  ListItem,
  // ListItemIcon,
  ListItemButton,
  ListItemText,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
// import { ConstructionOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    color: ("#cf3439"),
    backgroundColor: "#e5fcff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const theme = createTheme({
  date: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    time: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function compareNumbers(a, b) {
  return a - b;
}

const subjectName = ["","Toán học","Vật lí","Hóa học","Sinh học","Ngữ văn","Tiếng Anh"]

const ExamEnrollment = () => {
  // const theme = useTheme();
  let navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const { orderCode } = useSelector((state) => state.order);
  // const dispatch = useDispatch();
  const descriptionElementRef = useRef(null);
  const [orderCode, setOrderCode] = useState('');
  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState('');
  const [turns, setTurns] = useState([]);
  const [selectedTurns, setSelectedTurns] = useState([]);
  const [bookedTurns, setBookedTurns] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [codeArr, setCodeArr] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [scroll, setScroll] = useState('paper');

  useEffect(() => {
    if (isLoggedIn) {
      ExamService.getExams().then(
        (response) => {
          setExams(response.data.data);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
            console.log(_content);
        }
      );
      if (!(localStorage.getItem("selectedTurns") === null || localStorage.getItem("selectedTurns").length === 0)) {
        setSelectedTurns(JSON.parse("[" + localStorage.getItem("selectedTurns") + "]"))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedTurns", selectedTurns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurns]);

  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const getSelectedSubjects = () => {
    setSelectedSubjects([])
    setCodeArr([])
    let _price = 0
    selectedTurns.sort(compareNumbers)
    for (let item of selectedTurns){
      let _exam = Math.floor(item/100)
      if(_exam !== exam.id) continue
      let _turn = Math.floor((item % 100) / 10)
      let _subject = item % 10
      let _turnObj = turns.find((ele) => {
        return ele.turn === _turn
      })
      let _subjectObj = _turnObj.subjects.find((ele) => {
        return ele.id === _subject
      })
      _price += parseInt(_subjectObj.price)
      setSelectedSubjects( current => [
        ...current, 
        {
          turn: _turnObj.turn,
          date: _turnObj.date,
          time: _turnObj.time,
          subject: subjectName[_subjectObj.id],
          price: parseInt(_subjectObj.price)
        }
      ])
      setCodeArr( current => [...current, item])
    }
    setTotalPrice(_price)
  }

  const checkSelectedTurn = (index) => {
    index = parseInt(index)
    if(checkBookedTurn(index)){
      return bookedTurns.includes(index)
    }else{
      return selectedTurns.includes(index)
    }
  }

  const checkBookedTurn = (index) => {
    index = parseInt(index)
    var _exam = Math.floor(index / 100)
    var _turn = Math.floor((index % 100) / 10)
    var _subject = index % 10
    for(let item of bookedTurns){
      if(
        (_exam === (Math.floor(item / 100))) &&
        ((
          (item > (_exam * 100 + _turn * 10) && 
          item < (_exam * 100 + (_turn + 1) * 10))
        ) || 
        (item % 10 === _subject))
      ){
        return true
      }
    }
    return false
    // return bookedTurns.includes(index);
  }
  
  const handleChangeExam = async (event) => {
    setExam(event.target.value);
    let response = await OrderService.getNewOrderCode({examId: event.target.value.id})
    setOrderCode(response.data.data)
    // setBookedTurns(initBookedTurns)
    // setTurns(initExamTurns);
    ExamService.getTurnsByExam({examId: event.target.value.id}).then(
      (response) => {
        setTurns(response.data.data);
        ExamService.getBookedTurnsByExam({examId: event.target.value.id}).then(
          res => {
            setBookedTurns(res.data.data)
          }
        )
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          console.log(_content);
      }
    );
    getSelectedSubjects();
  };

  const handleListItemClick = (event, code) => {
    code = parseInt(code)
    var _exam = Math.floor(code / 100)
    var _turn = Math.floor((code % 100) / 10)
    var _subject = code % 10

    if(selectedTurns.includes(code)){
      setSelectedTurns(current =>
        current.filter(item => {
          if (item !== code){
            return true;
          }
          return false;
        }),
      );
    }else{
      setSelectedTurns(current =>
        current.filter(item => {
          //xóa các môn thi khác nhau đăng kí trùng ca thi hoặc các môn thi khác nhau đăng kí trùng đợt thi
          if(
            (_exam === (Math.floor(item / 100))) &&
            ((
              (item > (_exam * 100 + _turn * 10) && 
              item < (_exam * 100 + (_turn + 1) * 10))
            ) || 
            (item % 10 === _subject))
          ){
            return false;
          }else{
            return true;
          }
        }),
      );
      setSelectedTurns( current => [...current, parseInt(code)])
    }
  };

  const handleClickOpen = (scrollType) => () => {
    setOpenDialog(true);
    setScroll(scrollType);
    getSelectedSubjects()    
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setOpenDialog(false);
  };

  const handlePaymentClick = () => {
    const data = {
      orderCode: orderCode,
      examId: exam.id,
      codeArr: codeArr
    }
    // console.log(data)
    OrderService.postOrderDetails(data).then(
      (response) => {
        // console.log(response.data.data);
        localStorage.removeItem("selectedTurns");
        localStorage.removeItem("orderCode");
        // navigate("/payment/DGNL/"+response.data.orderCode);
        navigate("/payment/" + process.env.REACT_APP_SERVICE + "/"+response.data.orderCode);
        window.location.reload();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(_content);
      }
    );
  }

  return (
    <div>
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardHeader
            title={
              <Typography variant="h5">
                Đăng kí dự thi
            </Typography>
            }
          />
          <Divider variant="middle" sx={{ border: '1px solid', borderColor: 'text.primary',}} />
          <CardContent 
            sx={{ 
              display: 'grid', 
              gridAutoColumns: '1fr',
              gap: 1,
            }}
          >
            {exams.length > 0 && (
              <>
              <FormControl 
                sx={{ 
                  '& label': { mt: 0 }, 
                  m: 1 , 
                  minWidth: 120
                }}
              >
                <InputLabel id="select-label">Chọn Kì thi</InputLabel>
                <Select
                  labelId="select-label"
                  id="exam-select"
                  label="Chọn kì thi"
                  value={exam}
                  onChange={handleChangeExam}
                >
                {exams.map((item, index) => (
                  <MenuItem
                    key={ index }
                    value={item}
                    disabled={item.status !== "OPEN" ? true : false}
                  >
                    Đợt {item.turn} tại {item.location} từ {item.openAt} đến {item.closeAt} { item.msg }
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <Divider />
              {
                exam && turns.length > 0 && (
                  < >
                  <Alert severity="info">
                    <Typography variant="body">
                      Vui lòng nhấn chọn vào môn thi để đăng kí
                    </Typography>
                  </Alert>
                  { selectedTurns.length > 0 && (                  
                    <Grid container justifyContent="right" sx={{ pr: 1, my: 1 }}>
                      <ButtonGroup variant="outlined" aria-label="text button group">
                        <Button variant="contained" color="warning" onClick={handleClickOpen('paper')}>Thanh toán lệ phí</Button>
                      </ButtonGroup>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid 
                      container 
                      justifyContent="center" 
                      spacing={1} 
                      // sx={{ mb:3 }}
                    >
                      {turns.map((turn, index) => {
                        if(turn.visible > 0) return (
                        <Grid key={ index } item>
                          <Card
                            sx={{
                              minHeight: 340,
                              backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            }}
                          >
                            <CardHeader
                              title={
                                <>
                                  <Typography component="div" variant="h6">
                                    {/* Ca {turn.turn} */}
                                    <Typography component="div" size="small" color="text.secondary">
                                      Ngày thi: <Typography sx={{ fontWeight: 'bold', color: '#054675' }} component="span">{turn.date}</Typography>
                                    </Typography>
                                    <Typography component="div" size="small" color="text.secondary">
                                      Giờ thi: <Typography sx={{ fontWeight: 'bold', color: '#cf3439' }} component="span">{turn.time}</Typography>
                                    </Typography>
                                  </Typography>
                                </>
                              }
                            />
                            <CardContent>
                              <List component="nav" aria-label="secondary mailbox folder">
                              {turn.subjects.map((subject, index) => {
                                if(subject.maxSlots > 0) return (
                                  <ListItem
                                    key={ index }
                                    secondaryAction={
                                      <Chip sx={{ width: 55 }} edge="end" size="small" variant="outlined" color="success" icon={<GroupIcon />} label={ subject.availableSlots } />
                                    }
                                    // disablePadding
                                  >
                                    <ListItemButton
                                      key={ index }
                                      selected={ checkSelectedTurn((exam.id.toString() + turn.turn.toString() + (subject.id).toString())) }
                                      onClick={(event) => handleListItemClick(event, (exam.id.toString() + turn.turn.toString() + (subject.id).toString()))}
                                      disabled={ checkBookedTurn((exam.id.toString() + turn.turn.toString() + (subject.id).toString())) || (subject.availableSlots === 0) }
                                    >
                                      <ListItemText primary= { subjectName[subject.id]} />
                                      {/* <ListItemText primary= { subjectName[subject.id] + '-' + (exam.id.toString() + turn.turn.toString() + (subject.id).toString()) } /> */}
                                    </ListItemButton>
                                  </ListItem>
                                )
                              } )}
                              </List>
                            </CardContent>
                          </Card>
                        </Grid>
                      )})}
                    </Grid>
                  </Grid>
                  </>
                )
              }
              </>
            )}
            {exams.length === 0 && (
              <Alert severity="warning">
                <Typography variant="body">
                  Chưa có thông tin Kì thi
                </Typography>
              </Alert>
            )}
          </CardContent>
          <CardActions 
            className="float-end"
            sx={{
              mb: 1, mr: 2
            }}
          >
            { exam && selectedTurns.length > 0 && (
            <ButtonGroup variant="outlined" aria-label="text button group">
              <Button variant="contained" color="warning" onClick={handleClickOpen('paper')}>Thanh toán lệ phí</Button>
            </ButtonGroup>
            )}
          </CardActions>
        </Card>
        </Paper>
      </Box>
      { exam && selectedTurns.length > 0 && (
        <Dialog
          fullWidth
          maxWidth={ "md" }
          open={ openDialog }
          onClose={ handleClose }
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <>
            <Typography variant="subtitle1" component="div" sx={{ fontWeight: "bold", }}>Kì thi: Đợt {exam.turn} tại {exam.location} từ {exam.openAt} đến {exam.closeAt}</Typography>
            <Typography variant="subtitle1" component="div" className="text-primary">Mã đăng ký: {orderCode}</Typography>
            <Typography variant="subtitle1" component="div">Ngày đăng ký: {dayjs().format('DD/MM/YYYY hh:mm:ss')}</Typography>
            </>
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <TableContainer 
              component={Paper}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell>Thời gian</StyledTableCell> */}
                    <StyledTableCell align="center">Môn thi</StyledTableCell>
                    <StyledTableCell align="center">Lệ phí</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSubjects.map((row, index) => (
                    
                    <StyledTableRow key={index}>
                      {/* <StyledTableCell component="th" scope="row">
                        Ca {row.turn} - {row.time} ngày {row.date}
                      </StyledTableCell> */}
                      <StyledTableCell>
                        {row.subject} 
                        <br></br>
                        {/* { (row.turn % 2) === 1 ? "Sáng" : "Chiều" }  */}
                        {row.date}
                      </StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat().format(row.price)} đ</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCellTotal align="right">Tổng lệ phí</StyledTableCellTotal>
                    <StyledTableCellTotal align="right">{new Intl.NumberFormat().format(totalPrice)} đ</StyledTableCellTotal>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Alert severity="warning" sx={{ mt:1 }}>
              <Typography variant="body">
                Lưu ý: bạn cần hoàn tất việc thanh toán lệ phí trong vòng 24 giờ tính từ ngày đăng ký!
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions sx={{ px:2.5 }}>
            <Button variant="outlined" onClick={handleClose}>Quay lại</Button>
            {
              selectedSubjects.length > 0 && (
                <Button variant="contained" color="warning" onClick={handlePaymentClick}>Thanh toán</Button>
              )
            }
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ExamEnrollment;