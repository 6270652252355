import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from "./Footer.js";

const LoginLayout = () => {
  return (
    <div id="back-to-top-anchor">
      <Outlet />
      <Footer />
    </div>
  )
}

export default LoginLayout;