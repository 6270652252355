import React , { useState, useEffect  } from "react";
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";

import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Alert,
  AlertTitle,
  Card,
  CardHeader,
  CardContent,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  Stack,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";

import PaymentService from "../../services/payment.service";
import OrderService from "../../services/order.service";

const PaymentReturn = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const orderId = searchParams.get('orderId')
  const extraData = searchParams.get('extraData')
  
  const [ orderCode, setOrderCode ] = useState('');
  const [ finished, setFinished ] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }
    if (!orderId) {
      return <Navigate to="/profile" />
    }
    const code = orderId.split("_")
    if(code.length !== 2){
      return <Navigate to="/profile" />
    }
    setOrderCode(code[0])
    console.log(orderCode)
    OrderService.getOrderDetails({code: code[0]}).then(
      (res) => {                
        PaymentService.postMomoReturn({
          partnerCode: searchParams.get('partnerCode'),
          orderId: searchParams.get('orderId'),
          requestId: searchParams.get('requestId'),
          amount: searchParams.get('amount'), 
          orderInfo: searchParams.get('orderInfo'), 
          orderType: searchParams.get('orderType'), 
          transId: searchParams.get('transId'), 
          resultCode: searchParams.get('resultCode'), 
          message: searchParams.get('message'), 
          payType: searchParams.get('payType'), 
          responseTime: searchParams.get('responseTime'), 
          extraData: searchParams.get('extraData'), 
          signature: searchParams.get('signature')
        }).then(
          (resMomo) => {
            // return <Navigate to={"/payment/" + code[0]} />
            setFinished(true)
          },
          (error) => {
            let _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              console.log(_content);
          }
        )
      },
      (error) => {
        let _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(_content);
      }
    );
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Navigate to={"/payment/" + extraData + "/" + orderCode} />
    } else {
      // Render a countdown
      return <span>Vui lòng không đóng trình duyệt! Hệ thống sẽ tự chuyển trang sau {seconds} giây</span>;
    }
  };


  return (
    <div>
      <Box
        sx={{
          borderRadius: 0.5,
        }}
      >
        <Paper 
          elevation={3}
          sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            width: 9/10
          }}
        >
        <Card 
          variant="outlined"
        >
          <CardContent>
            <Alert 
              severity={
                ((searchParams.get('resultCode')) == 0)?"success":"error"
              }
            >
              <AlertTitle>{searchParams.get('message')}</AlertTitle>
              {/* Kết quả giao dịch đang được cập nhật! <br></br> */}
              {finished && (<Countdown 
                date={Date.now() + 10000} 
                renderer={renderer}
              />)}
            </Alert>
          </CardContent>          
        </Card>
        </Paper>
      </Box>
    </div>
  );
};

export default PaymentReturn;