import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const _register = createAsyncThunk(
    "auth/register",
    async ({ fullname, cccd, birthday, gender, phone, email, password, acceptTerms }, thunkAPI) => {
        try {
            const response = await AuthService.register(fullname, cccd, birthday, gender, phone, email, password, acceptTerms);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const _login = createAsyncThunk(
    "auth/login",
    async ({ cccd, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(cccd, password);
            return { user: data };
        } catch (error) {
            const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const _logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducer: {
        //
    },
    extraReducers: builder => {
        builder
        .addCase(_register.fulfilled, (state, action) => {
            state.isLoggedIn = false;
        })
        .addCase(_register.rejected, (state, action) => {
            state.isLoggedIn = false;
        })
        .addCase(_login.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        })
        .addCase(_login.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        })
        .addCase(_logout.fulfilled, (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        })
    },
});

const { reducer } = authSlice;
export default reducer;