import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + "api/payment/"

const postMomoRequest = (data) => {
    return axios.post(API_URL + "momo", data, { headers: authHeader() });
};
const postMomoReturn = (data) => {
    return axios.post(API_URL + "momo/return", data, { headers: authHeader() });
};

const getBankingPaymentByCode = (data) => {
    return axios.get(API_URL + "banking/check/" + data.code, { headers: authHeader() });
};

const paymentService = {
    postMomoRequest,
    postMomoReturn,
    getBankingPaymentByCode
};
  
export default paymentService