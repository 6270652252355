import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import orderReducer from "./slices/order";

const reducer = {
    auth: authReducer,
    message: messageReducer,
    order: orderReducer
}

const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;