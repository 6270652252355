import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import PublicService from "../../services/public.service";

import { 
    // Paper,
    Box,
    // Grid,
    Typography,
    // FormControlLabel,
    // Checkbox,
    Button, 
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme, styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ("#104675"),
    color: theme.palette.common.white,
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCellTotal = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontWeight: "bold",
    color: ("#cf3439"),
    backgroundColor: "#e5fcff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Search = () => {
    let navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [results, setResults] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, []);

    const initialValues = {
        cccd: "",
    };

    const validationSchema = Yup.object().shape({
        cccd: Yup.string().required("Thông tin này không được bỏ trống!"),
    });

    const handleSearch = (formValue) => {
        const { cccd } = formValue;
        PublicService.getSearchResult(cccd).then( response => {
            setResults(response.data.data)
        })
    }

    return (
    <div className="col-md-12 login-form">
        <div className="card card-container">
            <img
                src= "/logo_hcmue.png"
                alt="profile-img"
                className="profile-img-card"
            />
            <Box
                sx={{
                '& .MuiTextField-root': { py: 1 },
                mb: 2
                }}
                className="text-center"
            >
                <Typography variant="h4" gutterBottom>
                    TRA CỨU KẾT QUẢ DỰ THI
                </Typography>
            </Box>
            <Box
                sx={{
                '& .MuiTextField-root': { py: 1 },
                mb: 2
                }}
                noValidate
                autoComplete="off"
            >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
            >
                {props => (
                <Form>
                    <TextField
                        fullWidth
                        id="cccd"
                        name="cccd"
                        label="Nhập Số Căn cước công dân"
                        variant="standard"
                        value={props.values.cccd}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.touched.cccd && Boolean(props.errors.cccd)}
                        helperText={props.touched.cccd && props.errors.cccd}
                        autoComplete="off"
                    />
                    <Button color="primary" fullWidth variant="contained" size="large" type="submit" sx={{ mt:1 }}>Tra cứu</Button>
                </Form>
                )}
            </Formik>
            {message && (
            <div className="form-group mt-3">
                <div className="alert alert-danger" role="alert">
                {message}
                </div>
            </div>
            )}
            </Box>
            {
                results && (
                    <>
                    <Divider variant="middle">Kết quả tra cứu</Divider>
                    <Box
                        sx={{
                        '& .MuiTextField-root': { py: 1 },
                        mb: 2
                        }}
                    >
                        <TableContainer 
                        component={Paper}
                        sx={{
                            mt:3
                        }}
                        >
                            <Table aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Kì thi</StyledTableCell>
                                    <StyledTableCell align="center">Họ tên</StyledTableCell>
                                    <StyledTableCell align="center">Loại điểm</StyledTableCell>
                                    <StyledTableCell align="center">Phiếu điểm</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {results.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            Đợt {row[7]} - Năm {row[6]}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row[0]}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row[2]}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Button size="small" color="success" variant="contained" href={row[4]} target="_blank">Tải Phiếu điểm</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    </>
                )
            }
        </div>
    </div>
    );
};

export default Search;